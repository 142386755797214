/* =================================== */
/*  6. Footer
/* =================================== */

#footer {
  background: #fff;
  padding: 66px 0px;
  padding: 4.125rem 0;
  .footer-copyright {
    border-top: 1px solid #e2e8ea;
    padding: 0px 0px;
    color: #67727c;
    .nav {
      .nav-item .nav-link {
        color: #67727c;
      }
      .nav-link:hover {
        color: var(--#{$prefix}themecolor);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }
  .nav.flex-column .nav-item {
    padding: 0px;
    .nav-link {
      margin: 0.7rem 0px;
    }
  }
}

html:not([dir='rtl']) #footer {
  .nav {
    .nav-item {
      &:first-child .nav-link {
        padding-left: 0px;
      }
      &:last-child .nav-link {
        padding-right: 0px;
      }
    }
  }
}

html[dir='rtl'] #footer {
  .nav {
    .nav-item {
      &:first-child .nav-link {
        padding-right: 0px;
      }
      &:last-child .nav-link {
        padding-left: 0px;
      }
    }
  }
}

/*=== 6.1 Social Icons ===*/
.social-icons {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  li {
    margin: 2px 6px;
    padding: 0;
    overflow: visible;
    a {
      display: block;
      height: 26px;
      line-height: 26px;
      width: 26px;
      font-size: 18px;
      text-align: center;
      color: #4d555a;
      text-decoration: none;
      @include transition(all 0.2s ease);
    }
    i {
      line-height: inherit;
    }
  }

  &.social-icons-sm li {
    margin: 2px 4px;
  }
  &.social-icons-sm li a {
    font-size: 14px;
  }
  &.social-icons-lg li a {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 22px;
  }
  &.social-icons-light li a {
    color: #eee;
  }
  &.social-icons-muted li a {
    // color: #aab1b8;
    color: #fff;
    font-size: 1.8rem;
  }
  li:hover {
    a {
      color: #999;
    }
  }
  li:hover.social-icons-twitter a,
  &.social-icons-colored li.social-icons-twitter a {
    color: $twitter;
  }
  li:hover.social-icons-facebook a,
  &.social-icons-colored li.social-icons-facebook a {
    color: $facebook;
  }
  li:hover.social-icons-linkedin a,
  &.social-icons-colored li.social-icons-linkedin a {
    color: $linkedin;
  }
  li:hover.social-icons-rss a,
  &.social-icons-colored li.social-icons-rss a {
    color: $rss;
  }
  li:hover.social-icons-dribbble a,
  &.social-icons-colored li.social-icons-dribbble a {
    color: $dribbble;
  }
  li:hover.social-icons-github a,
  &.social-icons-colored li.social-icons-github a {
    color: $github;
  }
  li:hover.social-icons-behance a,
  &.social-icons-colored li.social-icons-behance a {
    color: $behance;
  }
  li:hover.social-icons-google a,
  &.social-icons-colored li.social-icons-google a {
    color: $google;
  }
  li:hover.social-icons-pinterest a,
  &.social-icons-colored li.social-icons-pinterest a {
    color: $pinterest;
  }
  li:hover.social-icons-youtube a,
  &.social-icons-colored li.social-icons-youtube a {
    color: $youtube;
  }
  li:hover.social-icons-instagram a,
  &.social-icons-colored li.social-icons-instagram a {
    color: $instagram;
  }
  li:hover.social-icons-skype a,
  &.social-icons-colored li.social-icons-skype a {
    color: $skype;
  }
  li:hover.social-icons-email a,
  &.social-icons-colored li.social-icons-email a {
    color: $email;
  }
  li:hover.social-icons-vk a,
  &.social-icons-colored li.social-icons-vk a {
    color: $vk;
  }
  li:hover.social-icons-xing a,
  &.social-icons-colored li.social-icons-xing a {
    color: $xing;
  }
  li:hover.social-icons-tumblr a,
  &.social-icons-colored li.social-icons-tumblr a {
    color: $tumblr;
  }
  li:hover.social-icons-reddit a,
  &.social-icons-colored li.social-icons-reddit a {
    color: $reddit;
  }
  li:hover.social-icons-delicious a,
  &.social-icons-colored li.social-icons-delicious a {
    color: $delicious;
  }
  li:hover.social-icons-stumbleupon a,
  &.social-icons-colored li.social-icons-stumbleupon a {
    color: $stumbleupon;
  }
  li:hover.social-icons-digg a,
  &.social-icons-colored li.social-icons-digg a {
    color: $digg;
  }
  li:hover.social-icons-blogger a,
  &.social-icons-colored li.social-icons-blogger a {
    color: $blogger;
  }
  li:hover.social-icons-flickr a,
  &.social-icons-colored li.social-icons-flickr a {
    color: $flickr;
  }
  li:hover.social-icons-vimeo a,
  &.social-icons-colored li.social-icons-vimeo a {
    color: $vimeo;
  }
  li:hover.social-icons-yahoo a,
  &.social-icons-colored li.social-icons-yahoo a {
    color: $yahoo;
  }
  li:hover.social-icons-apple a,
  &.social-icons-colored li.social-icons-apple a {
    color: $appstore;
  }
  &.social-icons-colored li {
    &:hover a {
      color: #999;
    }
  }
}

html:not([dir='rtl']) .social-icons {
  li {
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}
html[dir='rtl'] .social-icons {
  li {
    &:first-child {
      margin-right: 0px;
    }
    &:last-child {
      margin-left: 0px;
    }
  }
}

/*=== 6.2 Back to Top ===*/
#back-to-top {
  display: none;
  cursor: pointer;
  position: fixed;
  z-index: 1030;
  bottom: 14px;
  right: 12px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
  font-size: 18px;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  @include transition(all 0.3s ease-in-out);
  @include box-shadow(0px 5px 15px rgba(0, 0, 0, 0.15));
  &:hover {
    background-color: var(--#{$prefix}themecolor);
    @include box-shadow(0px 5px 15px rgba(0, 0, 0, 0.25));
    @include transition(all 0.3s ease-in-out);
  }
}

html[dir='rtl'] body:not(.side-header-right) #back-to-top,
html:not([dir='rtl']) .side-header-right #back-to-top {
  left: 10px;
  right: auto;
}

@include media-breakpoint-only(xs) {
  #back-to-top {
    z-index: 1029;
  }
}
